import React, { FunctionComponent } from 'react';

const Ghosts: FunctionComponent = () => (
  <svg width="223" height="131" viewBox="0 0 223 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M161 12.3999C188.379 12.3999 210.6 34.6207 210.6 61.9999L210.6 111.6L111.4 111.6L111.4 61.9999C111.4 34.6207 133.62 12.3999 161 12.3999Z" fill="#163BF3" />
    <path d="M61.9996 12.3999C89.3788 12.3999 111.6 34.6207 111.6 61.9999L111.6 111.6L12.3996 111.6L12.3996 61.9999C12.3996 34.6207 34.6204 12.3999 61.9996 12.3999Z" fill="#FF7262" />
    <ellipse cx="139.3" cy="55.8002" rx="15.5" ry="18.6" fill="white" />
    <circle cx="139.3" cy="49.6004" r="6.2" fill="black" />
    <ellipse cx="182.7" cy="55.8002" rx="15.5" ry="18.6" fill="white" />
    <circle cx="182.7" cy="49.6004" r="6.2" fill="black" />
    <ellipse cx="83.7002" cy="49.6" rx="15.5" ry="18.6" fill="white" />
    <ellipse cx="74.4002" cy="52.6999" rx="6.2" ry="9.3" fill="black" />
    <ellipse cx="46.5" cy="49.6" rx="15.5" ry="18.6" fill="white" />
    <ellipse cx="37.2" cy="52.6999" rx="6.2" ry="9.3" fill="black" />
  </svg>
);

export default Ghosts;
