import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons"

import clsx from "clsx"
import useOnScreen from "./useOnScreen"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

export interface NewTimeline {
  color: string
  direction: "DOWN" | "NEUTRAL" | "UP"
  icon: IconDefinition
  iconImage: string
  image: JSX.Element
  message: string | JSX.Element
  title: string
  data: []
}

interface Props {
  events: NewTimeline[]
}

const NewTimeline: FunctionComponent<Props> = ({ events }) => {
  const [index, setIndex] = useState(0)
  const [ind, setInd] = useState(0)

  const interval = useRef<NodeJS.Timeout | null>(null)
  const ref = useRef<HTMLDivElement>(null)

  const isVisible = useOnScreen(ref, "-100px")

  useEffect(() => {
    if (isVisible) {
      interval.current = setInterval(() => {
        setIndex(i => {
          if (i === 4) {
            return 0
          }

          return i + 1
        })
      }, 1900000)
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [isVisible])

  return (
    <div
      className="gap-4 grid grid-cols-1 sm:grid-cols-2 sm:gap-8 xl:gap-32"
      ref={ref}
    >
      <div className="bg-white flex items-center justify-center md:px-4 py-0 relative z-0 sm:py-8 lg:bg-gray">
        {events.map((elem, eventIndex) => {
          let color = "gray-dark"

          if (eventIndex <= index) {
            color = elem.color
          }
          return (
            <div
              className={clsx(index === eventIndex ? "block" : "hidden")}
              key={eventIndex}
            >
              <div className="max-w-md relative">
                <div
                  className={clsx(
                    "justify-end items-center hidden md:flex md:justify-center ml-30 mb-8",
                    `text-${color}`
                  )}
                >
                  {/* <FontAwesomeIcon className="text-lg" icon={elem.icon} /> */}

                  <h3
                    className={clsx(
                      "font-Orbitron ml-4 text-left lg:text-2xl text-lg ",
                      `text-${color}`
                    )}
                  >
                    {elem?.data?.[ind]?.title}
                  </h3>
                </div>
                <div className="md:px-4 px-0">
                  <div className="md:bg-gray md:p-4 relative rounded-md md:shadow-lg text-center">
                    <div
                      className={clsx(
                        "absolute h-24 -left-4 -top-4 w-24 -z-1 md:block hidden",
                        elem.color === "blue" && "bg-blue",
                        elem.color === "coral" && "bg-coral",
                        elem.color === "teal" && "bg-teal"
                      )}
                    />

                    <div
                      className={clsx(
                        "absolute -bottom-4 h-24 -right-4 w-24 -z-1 md:block hidden",
                        elem.color === "blue" && "bg-blue",
                        elem.color === "coral" && "bg-coral",
                        elem.color === "teal" && "bg-teal"
                      )}
                    />
                    {elem?.data?.[ind]?.image}
                  </div>
                </div>

                <div className="flex justify-between md:mb-4 md:mt-8 mt-2 mb-2">
                  <div
                    className={clsx(
                      "border-2 p-1 rounded-full ",
                      elem.color === "blue" && "border-blue",
                      elem.color === "coral" && "border-coral",
                      elem.color === "teal" && "border-teal",
                      index === 0 ? "border-gray-dark" : "animate-pulse"
                    )}
                  >
                    <button
                      className={clsx(
                        "h-8 flex items-center justify-center rounded-full text-white w-8",
                        elem.color === "blue" && "bg-blue",
                        elem.color === "coral" && "bg-coral",
                        elem.color === "teal" && "bg-teal",
                        ind === 0 && "bg-gray-dark"
                      )}
                      aria-label="chevron-left"
                      type="button"
                      onClick={() => {
                        if (ind > 0) {
                          setInd(i => i - 1)
                        }
                      }}
                      onClick={() => {
                        if (ind > 0) {
                          setInd(i => i - 1)
                        } else if (index === 0) {
                        } else {
                          setIndex(index === 0 ? index : index - 1)
                          if (index > 0) {
                            setInd(i => (index == 0 ? i : ind == 0 ? i : i - 1))
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-lg text-white"
                        icon={faChevronLeft}
                      />
                    </button>
                  </div>

                  {elem.direction === "NEUTRAL" ? null : (
                    <div className="flex items-center">
                      <div
                        className={clsx(
                          "border-2 mr-2 p-1 rounded-full",
                          elem.color === "blue" && "border-blue",
                          elem.color === "coral" && "border-coral",
                          elem.color === "teal" && "border-teal"
                        )}
                      >
                        <div
                          className={clsx(
                            "flex h-8 items-center justify-center rounded-full text-white w-8",
                            elem.color === "blue" && "bg-blue",
                            elem.color === "coral" && "bg-coral",
                            elem.color === "teal" && "bg-teal"
                          )}
                        >
                          <FontAwesomeIcon
                            className="text-lg"
                            icon={
                              elem.direction === "UP" ? faArrowUp : faArrowDown
                            }
                          />
                        </div>
                      </div>

                      <p
                        className={clsx(
                          "font-bold uppercase",
                          elem.color === "blue" && "text-blue",
                          elem.color === "coral" && "text-coral",
                          elem.color === "teal" && "text-teal"
                        )}
                      >
                        {`Level ${elem.direction}`}
                      </p>
                    </div>
                  )}

                  <div
                    className={clsx(
                      "border-2 p-1 rounded-full",
                      elem.color === "blue" &&
                        index < events.length - 1 &&
                        "border-blue",
                      elem.color === "coral" &&
                        index < events.length - 1 &&
                        "border-coral",
                      elem.color === "teal" &&
                        index < events.length - 1 &&
                        "border-teal",
                      index === events.length - 1
                        ? "border-gray-dark"
                        : "animate-pulse"
                    )}
                  >
                    <button
                      className={clsx(
                        "h-8 flex items-center justify-center rounded-full text-white w-8",
                        elem.color === "blue" &&
                          index < events.length - 1 &&
                          "bg-blue",
                        elem.color === "coral" &&
                          index < events.length - 1 &&
                          "bg-coral",
                        elem.color === "teal" &&
                          index < events.length - 1 &&
                          "bg-teal",
                        index === events.length - 1 && "bg-gray-dark"
                      )}
                      type="button"
                      aria-label="chevron-right"
                      onClick={() => {
                        if (ind < elem.data.length - 1) {
                          setInd(i => i + 1)
                        } else if (index === 4) {
                        } else {
                          setIndex(index < 5 ? index + 1 : index)
                          if (index < 5) {
                            console.log(index)
                            setInd(i => (index == 4 ? i : 0))
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-lg text-white"
                        icon={faChevronRight}
                      />
                    </button>
                  </div>
                </div>

                <div className="font-bold hidden text-xl  text-left sm:block">
                  {elem?.data?.[ind]?.message}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="sm:py-8 sm:space-y-6 lg:space-y-0">
        {events.map((elem, eventIndex) => {
          let color = "gray-dark"

          if (eventIndex <= index) {
            color = elem.color
          }

          return (
            <div
              className={clsx(
                eventIndex === index ? "block md:animate-pulse" : "hidden",
                "sm:block"
              )}
              key={eventIndex}
            >
              <button
                className="flex items-center mx-auto transition-opacity hover:opacity-70 sm:mx-0"
                type="button"
                onClick={() => {
                  setIndex(eventIndex)
                  setInd(0)

                  if (interval.current) {
                    clearInterval(interval.current)
                  }
                }}
              >
                <div
                  className={clsx(
                    "border-2 p-1 rounded-full",
                    `border-${color}`
                  )}
                >
                  <div
                    className={clsx(
                      "flex h-8 items-center justify-center rounded-full text-white w-8"
                    )}
                  >
                    {elem?.icon ? (
                      <FontAwesomeIcon className="text-lg" icon={elem.icon} />
                    ) : (
                      <img
                        alt="will moore 5 cores mindset"
                        placeholder="blurred"
                        src={elem?.iconImage}
                        loading="lazy"
                      />
                    )}{" "}
                  </div>
                </div>

                <div className="flex-1">
                  <h3
                    className={clsx(
                      "font-Orbitron ml-4 text-left text-xl",
                      `text-${color}`
                    )}
                  >
                    {elem?.data?.[ind]?.title}
                  </h3>
                </div>
              </button>

              <div className="font-bold mt-4 text-xl text-center sm:hidden">
                {elem?.data?.[ind]?.message}
              </div>

              {eventIndex < events.length - 1 ? (
                <div
                  className={clsx(
                    "h-12 hidden ml-5 w-1 lg:block",
                    `bg-${color}`
                  )}
                />
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewTimeline
